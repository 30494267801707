defineDs('/Components/DanskeSpil/Domain/Feature.Navigation/Scripts/ResponsibleGamingMenu', [
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
], async function (CrossWindowEvents, ApiRequest, Dictionary, Storage, Ensighten, TimeUtils, LoginCache, LoginController) {

  const RealityCheckDictionary = new Dictionary('/RealityCheckNotification');
  await RealityCheckDictionary.ready;
  const d = RealityCheckDictionary.get;
  let data = {};
  let timeoutId;

  const storageKey = 'realityCheckNotification';
  const oneMinuteInMs = 60 * 1000;

  // The period of time after a user has logged in during which we should not ask for notifications
  const loginGracePeriod = 60 * oneMinuteInMs;

  // Get local time in format 'h.mm'
  const getFormattedTime = (epoc) => {
    const date = new Date(epoc);
    return new Intl.DateTimeFormat('da-DK', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  // amount in cents converted to decimals '1,10' or integer if cents=0
  const getFormattedAmount = (amount) => {
    amount = Math.abs(amount) / 100;
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency: 'DKK',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount).replace(',00', '');
  };

  // Formatted amount in strong, always positive but with color and 'kr.'
  // ex: '<strong style="color: #d81818;">100,00 kr.</strong>'
  const getAmountString = (amount) => {
    const color = amount < 0 ? '#d81818' : '#080';
    return `<strong style="color: ${color};">${getFormattedAmount(amount)}</strong>`;
  };

  const replace = (text, data) => {
    const { createdAt, difference, loginHours, loginMinutes } = data;

    if (typeof text !== 'string' || !createdAt) {
      return;
    }

    const epoc = new Date(createdAt).getTime();

    const replacements = {
      '{time}': getFormattedTime(epoc),
      '{hours}': loginHours,
      '{r}': loginHours > 1 ? 'r' : '',
      '{ og {minutes} minut{ter}}': loginMinutes > 0 ? ` og ${loginMinutes} minut${loginMinutes !== 1 ? 'ter' : ''}` : '',
      '{amount}': getAmountString(difference)
    };

    for (let key in replacements) {
      text = text.replace(key, replacements[key]);
    }

    return text;
  };

  const getPrimaryText = (data) => {
    const { ruleVariant, difference } = data;
    const is24hVariant = ruleVariant === 'onlyTimeAbove24' || ruleVariant === 'above24HoursAndGame';
    const textKey = difference > 0 ? 'TextPlus' : difference < 0 ? 'TextMinus' : 'TextZero';
    const textKey24h = is24hVariant ? `${textKey}24h` : textKey;

    return replace(d(textKey24h), data);
  };

  const getSecondaryText = (data) => (data.designVariant === 'warning' ? d('TextWarn') : d('TextNoWarn'));

  const deleteDataFromStorage = () => Storage.set(storageKey, null);

  const getUserNameFromUserObj = () => LoginCache.getUserObj()?.customerInfo?.userName;

  const requestAccept = async ({ notificationId }) => {
    await ApiRequest({
      url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/realitycheck/v1/acceptRealityCheckNotification/' + notificationId,
      requireAuthCookie: true
    });
  };

  const getTrackingLabel = (data) => {
    let label = data.loginHours + ':' + data.loginMinutes.toString().padStart(2, '0');
    if (data.difference !== 0) {
      label += data.difference > 0 ? '_overskud_' : '_underskud_';
      label += getFormattedAmount(data.difference).replace(' ', '');
    }
    return label;
  };

  const showNotification = () => {
    if (!data?.displayNotification) {
      return;
    }

    let ctaFirst;
    let ctaSecond;
    let ctaThird;
    let ctaForth;
    let links = '';

    if (data.showResponsibleGamingLink) {
      links += ` <a href="#" data-tracking-action="cta: tidsnotifikation: klik_paa_omtanke" data-tracking-label="${getTrackingLabel(data)}">${d('PlayWithThoughtLinkText')}</a>.`;

      // ctaFirst = {
      //   isPrimary: true,
      //   label: d('PlayWithThoughtLinkText'),
      //   callback: () => {
      //     Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_omtanke', getTrackingLabel(data));
      //     window.open(data.responsibleGamingLink, d('PlayWithThoughtLinkText'), 'height=600');
      //   }
      // };
    }

    if (data.showAccountHistoryButton) {
      links += ` <a href="${data.accountHistoryLink}">${d('AccountHistoryLinkText')}</a>.`;

      // ctaSecond = {
      //   isPrimary: true,
      //   label: d('AccountHistoryLinkText'),
      //   callback: () => {
      //     Ensighten.pushGaEventOnPageRedirect('tidsnotifikation', 'klik_paa_historik', getTrackingLabel(data));
      //     window.location.href = data.accountHistoryLink;
      //   }
      // };
    }

    if (data.showContinueButton) {
      ctaThird = {
        label: d('ContinueLinkText'),
        isPrimary: true,
        callback: async () => {
          Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_fortsaet', getTrackingLabel(data));
          await requestAccept(data);
          data.displayNotification = false;
          updateData(data);
        },
      };
    }

    if (data.showCancelButton) {
      ctaForth = {
        label: d('CancelLinkText'),
        callback: () => {
          const cancelLink = data.cancelLink;
          deleteDataFromStorage();

          LoginController.logout({
            callback: function () {

              if (window.location.toString().indexOf('newWindow') > 0) {
                Ensighten.pushGaEvent('tidsnotifikation', 'klik_paa_log_ud', getTrackingLabel(data));
                setTimeout(() => {
                  window.close();
                }, 200);

              } else {
                Ensighten.pushGaEventOnPageRedirect('tidsnotifikation', 'klik_paa_log_ud', getTrackingLabel(data));
                window.location.href = cancelLink;
              }
            }
          });

        }
      };
    }

    let primaryText = getPrimaryText(data);

    if (!primaryText) {
      return;
    }

    let text = `<p>${primaryText}</p>`;
    let secondaryText = getSecondaryText(data) + links;

    if (secondaryText) {
      text += `<p>${secondaryText}</p>`;
    }

    document.dispatchEvent(new CustomEvent('modal:open', {
      detail: {
        icon: '/Components/DanskeSpil/Domain/Feature.Components/Graphics/ModalPopup/exclamation.svg',
        title: 'Tid til en pause?',
        text,
        ctaFirst,
        ctaSecond,
        ctaThird,
        ctaForth,
        hideCloseCta: true,
        hideCloseX: true,
      }
    }));

    Ensighten.pushGaEvent('tidsnotifikation', 'blevet_vist', getTrackingLabel(data));
  };

  const getRealityDataFromApi = async () => {
    let dataFromApi = null;

    const res = await ApiRequest({
      url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/realitycheck/v1/checkNotification',
      localCacheTTL: 60,
      requireAuthCookie: true,
    });

    if (res?.status === 'success') {
      dataFromApi = res.data;
      dataFromApi.lastRequest = new Date();
    }

    return dataFromApi;
  };

  const requestRealityCheck = async () => {
    if (data.lastRequest) {
      const lastEpoch = new Date(data.lastRequest).getTime();
      const nowEpoch = new Date().getTime();

      if ((nowEpoch - lastEpoch) < oneMinuteInMs) {
        updateData();
        return; // abort if last request is less than one minute
      }
    }

    const dataFromApi = await getRealityDataFromApi();

    if (dataFromApi) {
      updateData(dataFromApi);
    }
  };

  const nextCheckIsInTheFuture = ({ nextCheckAt }) => {
    const now = TimeUtils.getServerDateTime().getTime();
    const nextCheck = new Date(nextCheckAt).getTime();

    return nextCheck > now;
  };

  const getRealityDataFromStorage = () => {
    const dataFromStorage = JSON.parse(Storage.get(storageKey));
    const userNameFromStorage = dataFromStorage?.userName;
    const userNameFromUserObj = getUserNameFromUserObj();

    if (userNameFromStorage === userNameFromUserObj && nextCheckIsInTheFuture(dataFromStorage)) {
      return dataFromStorage;
    }
  };

  const checkForUpdatedData = (timeToNextUpdateInMs = oneMinuteInMs) => {
    if (timeoutId) {
      // If a setTimeout is already started, cancel that.
      clearTimeout(timeoutId);
    }

    // Minimum one minute until next check.
    timeToNextUpdateInMs = Math.max(timeToNextUpdateInMs, oneMinuteInMs);

    timeoutId = setTimeout(async () => {
      await requestRealityCheck();
    }, timeToNextUpdateInMs);
  };

  const updateData = (dataToSave = data) => {
    data = dataToSave;
    const currentServerTimeInMs = TimeUtils.getServerDateTime().getTime();

    const nextUpdateTimeInMs = new Date(data.nextCheckAt).getTime();
    let timeToNextUpdateInMs = nextUpdateTimeInMs - TimeUtils.getServerDateTime().getTime();

    if (data.displayNotification && (timeToNextUpdateInMs > oneMinuteInMs)) {
      // If notification is already displayed, update its values (time and/or amount) every minute.
      timeToNextUpdateInMs = oneMinuteInMs;
      data.nextCheckAt = new Date(currentServerTimeInMs + timeToNextUpdateInMs);
    }

    // If we're not displaying notification, strip unused data.
    if (!data.displayNotification) {
      data = {
        displayNotification: false,
        nextCheckAt: data.nextCheckAt,
        lastRequest: data.lastRequest,
      };
    }

    const userName = getUserNameFromUserObj();

    if (userName) {
      data.userName = userName;
    }

    Storage.set(storageKey, JSON.stringify(data));
    CrossWindowEvents.fire('ds.event.realityCheck.newDataReceived', data);

    showNotification();

    checkForUpdatedData(timeToNextUpdateInMs);
  };

  const setupEventListeners = () => {
    CrossWindowEvents.subscribe('ds.event.userLoggedIn', () => {
      const nextCheckAt = new Date(Date.now() + loginGracePeriod);
      const data = {
        displayNotification: false,
        nextCheckAt,
      };

      updateData(data);
    });

    CrossWindowEvents.subscribe('ds.event.userLoggedOut', () => {
      deleteDataFromStorage();
    });
  };

  const shouldNotDisplayNotification = (data) => data.displayNotification === false;

  const initialize = async () => {
    const isLoggedIn = LoginCache.isLoggedIn();

    if (isLoggedIn) {
      const dataFromStorage = await getRealityDataFromStorage();

      if (dataFromStorage && shouldNotDisplayNotification(dataFromStorage)) {
        // If we have data in storage but we don't have to display the notification, let's just update the data.
        updateData(dataFromStorage);
      } else {
        // If we don't have the data in storage, or have to display the notification anyways, let's get the latest data from the API.
        deleteDataFromStorage();

        getRealityDataFromApi().then((dataFromApi) => {
          if (dataFromApi) {
            updateData(dataFromApi);
          }
        });
      }

      setupEventListeners();
    }
  };


  if (document.readyState === 'loading') {
    // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', async () => {
      await initialize();
    });
  } else {
    // DOMContentLoaded has already fired
    await initialize();
  }

});



defineDs('DanskeSpil/Domain/Navigation/Scripts/GenericSmartBannerDetection', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
], function (Storage, Ensighten, Utils) {

  var cookieTtl = 0;

  var $smartbanner = $DS('.generic-smartbanner');
  var $staticSmartbannerFix = $DS('.generic-smartbanner-static-fix');

  if ($smartbanner.data('cookie-name')) {
    var region = $smartbanner.data('cookie-name').toLowerCase();
  }

  if ($smartbanner.data('cookie-ttl')) {
    cookieTtl = $smartbanner.data('cookie-ttl');
  }

  var detect = function () {
    // Get localstorage Expiry date
    var regionCheck = region + '-smartbanner';
    // var cookieExpiryDate = localStorage.getItem(regionCheck);
    var cookieExpiryDate = Storage.get(regionCheck);
    cookieExpiryDate = parseInt(cookieExpiryDate, 10);

    // Get current date
    var now = Date.parse(new Date());

    // Check if currentdate is beyond the expiry date, or the expirydate is unset.
    if (now > cookieExpiryDate || isNaN(cookieExpiryDate) == true || !cookieExpiryDate) {
      setEventListeners($smartbanner);
      showSmartbanner(true);
    }
  }.bind(this);

  // Cookie/localstorage functions
  var setEventListeners = function ($smartbanner) {
    $smartbanner.find('.generic-smartbanner__button--close').click(function () {
      // Utils.logDeprecated('GenericSmartBannerDetection.js - .generic-smartbanner__button--close clicked'); // Used on /eurojackpot, /lotto, /vikinglotto etc  (12/9-2022)

      setCookie();
      showSmartbanner(false);
    });

    $smartbanner.find('.generic-smartbanner__cta').click(function () {
      if ($smartbanner.data('show-ios-banner')) {
        setCookie();
      }

      // Create a virtuel event when clicking on SmartBanner link
      Ensighten.pushGaEvent('smartBanner', 'click', 'lotto_app', null, false);
    });
  };

  var setCookie = function () {
    // Calculate expiry date
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + cookieTtl);
    expiryDate = Date.parse(expiryDate);

    // Set the expiry date in the localstorage
    Storage.set(region + '-smartbanner', expiryDate);
  };

  var showSmartbanner = function (bool) {
    if (bool) {
      // Show smartbanner
      $staticSmartbannerFix.slideDown();
      $smartbanner.slideDown();

      // Create a virtuel event that SmartBanner is active
      Ensighten.pushGaEvent('smartBanner', 'visible', 'lotto_app', null, true);
    } else {
      $staticSmartbannerFix.slideUp();
      $smartbanner.slideUp();
    }
  };

  var init = function () {
    $DS(document).ready(function () {
      if ($DS('.generic-smartbanner').length >= 1) {
        detect();
      }
    });
  };

  init();
  if (document.querySelector('.generic-smartbanner')) {
    Utils.logDeprecated('GenericSmartBannerDetection.js - .generic-smartbanner found'); // Added by EKJEAR 2024-08-26
  }
});

defineDs('/Components/DanskeSpil/Domain/Feature.Navigation/Scripts/NavigationSideMenu', [], function () {
  const menus = document.querySelectorAll('.js-navigation-side-menu');

  if (menus.length === 0) {
    return;
  }

  const initialize = () => {
    menus.forEach((menu) => {
      const buttons = menu.querySelectorAll('li button');

      const activeLink = menu.querySelector('.active');

      buttons?.forEach((button) => {
        button.addEventListener('click', () => {
          button.closest('li').classList.toggle('open');
        });
      });

      // Open and set parent menu items active.
      let activeLinkParentLink = activeLink?.closest('ul').closest('li');
      activeLinkParentLink?.classList.add('active', 'open');
      activeLinkParentLink = activeLinkParentLink?.closest('ul').closest('li');
      activeLinkParentLink?.classList.add('active', 'open');
    });
  };

  initialize();

});

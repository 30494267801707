defineDs('/Components/DanskeSpil/Domain/Feature.Navigation/Scripts/NavigationTopMenu', [], function () {
  let menus;
  let hasInitialized = false;

  const supportsHover = () => window.matchMedia('(hover: hover)').matches;

  const toggleMenuItem = (e) => {
    let listElement = e.target;

    if (listElement.tagName === 'BUTTON') {
      listElement = listElement.parentElement;
    }

    if (listElement.tagName !== 'LI') {
      return;
    }

    const isOpen = listElement.classList.contains('open');
    closeAllMenuItems();

    listElement.classList.toggle('open', !isOpen);
    e.stopPropagation();
  };

  const getMainButtons = (menu) => menu?.querySelectorAll('.js-navigation-top-menu-item') || [];
  const getSubButtons = (menu) => menu?.querySelectorAll('.js-navigation-top-menu-sub-item') || [];

  const closeAllMenuItems = () => {
    menus.forEach((menu) => {
      getMainButtons(menu)?.forEach((button) => {
        button.closest('li').classList.remove('open');
      });

      getSubButtons(menu)?.forEach((button) => {
        button.closest('li').classList.remove('open');
      });
    });
  };

  const initialize = () => {
    menus = document.querySelectorAll('.js-navigation-top-menu');

    if (menus.length === 0 || hasInitialized) {
      return;
    }

    hasInitialized = true;

    menus.forEach((menu) => {
      // Set top level link as active if sub link is selected
      const activeLink = menu.querySelector('.active');
      if (activeLink) {
        const parentContainer = activeLink.closest('ul').closest('li');
        if (parentContainer) {
          parentContainer.classList.add('active');
        }
      }

      getMainButtons(menu)?.forEach((button) => {
        button.addEventListener('click', toggleMenuItem);

        if (supportsHover()) {
          // Device supports hover, add mouseenter/mouseleave event listeners
          button.closest('li').addEventListener('mouseenter', toggleMenuItem);
          button.closest('li').addEventListener('mouseleave', closeAllMenuItems);
        }
      });

      getSubButtons(menu)?.forEach((button) => {
        button.addEventListener('click', () => {
          button.closest('li').classList.toggle('open');
        });
      });
    });

    // Listen for clicks outside of menu items to close submenus
    document.addEventListener('click', (e) => {
      let clickedElement = e.target;

      if (clickedElement?.closest('.js-navigation-top-menu')) {
        // This is a click inside the menu, do nothing
        return;
      }

      // This is a click outside the menu, close the opened menu items
      closeAllMenuItems();
    });

    // Listen for events to close all submenus
    document.addEventListener('navigation:mainMenu:closeAllSubMenus', closeAllMenuItems);
  };

  initialize();
  window.addEventListener('resize', initialize);

});



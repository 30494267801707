defineDs('/Components/DanskeSpil/Domain/Feature.Navigation/Scripts/Navigation', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
], function (Utils) {
  const navigation = document.querySelector('.js-navigation');

  if (!navigation) {
    return;
  }

  const debounce = (func, wait, immediate) => {
    let timeout;
    return () => {
      const context = this;
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const resetNavigation = () => {
    document.dispatchEvent(new CustomEvent('navigation:scroll:show'));
    document.dispatchEvent(new CustomEvent('navigation:mainMenu:closeAllSubMenus'));
    document.dispatchEvent(new CustomEvent('navigation:chooseGames:close'));
    document.dispatchEvent(new CustomEvent('navigation:burgerMenu:close'));
    document.dispatchEvent(new CustomEvent('navigation:accountMenu:close'));
    document.dispatchEvent(new CustomEvent('responsible-gaming:close'));
  };

  const saveCurrentBrandURL = () => {
    if (
      document.querySelector('body.region-player-account-management')
      || !document.querySelector('.js-navigation')
    ) {
      return;
    }

    const prefixedKey = `${DS.Config.CONTEXT}currentBrandURL`;
    const value = document.location.pathname + document.location.search;

    const cookieSettings = { expires: 7, path: '/' };
    Utils.cookie(prefixedKey, value || '', cookieSettings);
  };

  const initialize = () => {
    saveCurrentBrandURL();

    document.addEventListener('navigation:reset', resetNavigation);

    // Handle scroll
    window.addEventListener('scroll', debounce(() => {
      document.dispatchEvent(new CustomEvent('navigation:scroll'));
    }, 10));

    // Handle screen rotation or resize
    window.addEventListener('resize', debounce(() => {
      resetNavigation();
    }, 100));

    // Close modal or reset navigation when pressing the Escape key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        const hasModalOpen = document.querySelector('.js-modal-popup.open');

        if (hasModalOpen) {
          document.dispatchEvent(new CustomEvent('modal:close'));
        } else {
          resetNavigation();
        }
      }
    });

  };

  if (navigation) {
    initialize();
  }

});



defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/ModalPopup', [], function () {
  const modal = document.querySelector('.js-modal-popup');

  if (!modal) return;

  let lastFocusedElement;
  let modalCtaButtons = modal.querySelectorAll('.js-modal-popup-button-cta');
  const modalCloseX = modal.querySelector('.js-modal-popup-close');
  const modalIcon = modal.querySelector('.js-modal-popup-icon');
  const focusableElements = 'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])';

  const setFocus = (containerElement) => {
    // Find all focusable elements in the menu
    const firstFocusableElement = containerElement.querySelectorAll(focusableElements)[0];

    // Update tabindex to make them focusable
    containerElement.querySelectorAll(focusableElements)
      .forEach((f) => f.setAttribute('tabindex', '0'));

    // Set focus to the first focusable element
    setTimeout(() => {
      firstFocusableElement.focus();
    }, 50);
  };

  const removeFocus = (containerElement) => {
    // Reset tabindex to make elements unfocusable again
    containerElement.querySelectorAll(focusableElements).forEach((f) => f.setAttribute('tabindex', '-1'));
  };

  const removeEventListenersOnModalCtaButtons = () => {
    // We remove all event listeners from all buttons, so that the modal can be reused by all modules, without
    // having to worry about any previously added event listeners being triggered.
    // We currently need to support iOS Safari 10.3, which doesn't support signal with AbortController.
    // When we no longer want to support iOS Safari 10.3, we can use signal with AbortController to cancel
    // fetch requests in a less hacky way. See guide: https://macarthur.me/posts/options-for-removing-event-listeners/
    // For now, we instead simply clone the buttons, as this will also remove any previously added event listeners.
    modalCtaButtons.forEach((button) => {
      button.classList.remove('ds-modal-popup__button--show');
      button.replaceWith(button.cloneNode(true));
    });

    // Get the cloned buttons.
    modalCtaButtons = modal.querySelectorAll('.js-modal-popup-button-cta');
  };


  const closeModal = () => {
    modal.classList.remove('ds-modal-popup--open');

    removeFocus(modal);
    document.dispatchEvent(new CustomEvent('overlay:modal:hide'));

    modal.ontransitionend = () => {
      lastFocusedElement?.focus();
      modal.ontransitionend = null;
    };
  };


  const setupCta = (element, { label, isPrimary = false, callback = null } = {}) => {
    if (element && label) {
      element.classList.toggle('ds-modal-popup__button--primary', isPrimary);

      // Clicking a button should close the modal, no matter what.
      element.addEventListener('click', closeModal);

      if (callback) {
        // Add any additional custom callback.
        element.addEventListener('click', callback);
      }

      element.innerHTML = label;
      element.setAttribute('data-tracking-label', 'button: ' + element.innerText);
      element.classList.add('ds-modal-popup__button--show');
    }
  };


  const openModal = ({ icon, title, text, ctaFirst, ctaSecond, ctaThird, ctaForth, hideCloseCta = false, hideCloseX = false }) => {
    removeEventListenersOnModalCtaButtons();

    // Remember where we came from, so that we can set focus on it when the modal closes.
    lastFocusedElement = document.activeElement;

    // Set the content of the modal.
    modal.querySelector('.js-modal-popup-title').innerHTML = title;
    modal.querySelector('.js-modal-popup-text').innerHTML = text;

    if (icon) {
      modalIcon.src = icon;
      modalIcon.classList.add('ds-modal-popup__icon--show');
    } else {
      modalIcon.classList.remove('ds-modal-popup__icon--show');
    }

    setupCta(modalCtaButtons[0], ctaFirst);
    setupCta(modalCtaButtons[1], ctaSecond);
    setupCta(modalCtaButtons[2], ctaThird);
    setupCta(modalCtaButtons[3], ctaForth);

    if (!hideCloseCta) {
      setupCta(modalCtaButtons[4], { label: 'Luk' });
    }

    modal.classList.toggle('ds-modal-popup--hide-close-x', hideCloseX);

    modal.classList.add('ds-modal-popup--open');
    document.dispatchEvent(new CustomEvent('overlay:modal:show'));

    setFocus(modal);
  };


  const initialize = () => {
    document.addEventListener('modal:open', ({ detail: { icon, title, text, ctaFirst, ctaSecond, ctaThird, ctaForth, hideCloseCta, hideCloseX } }) => {
      openModal({ icon, title, text, ctaFirst, ctaSecond, ctaThird, ctaForth, hideCloseCta, hideCloseX });
    });

    document.addEventListener('modal:close', closeModal);

    modalCloseX.addEventListener('click', closeModal);
  };


  initialize();

});

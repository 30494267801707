defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/Overlay', [], function () {
  const overlayNavigation = document.querySelector('.js-overlay-navigation');
  const overlayModal = document.querySelector('.js-overlay-modal');

  const initialize = () => {
    const toggleOverlay = (show, isNavigation = false) => {

      if (isNavigation) {
        overlayNavigation.classList.toggle('show', show);
        overlayNavigation.classList.toggle('hide-after-delay', !show);
      } else {
        overlayModal.classList.toggle('show', show);
        overlayModal.classList.toggle('hide-after-delay', !show);
      }

      const overlayIsVisible = overlayNavigation.classList.contains('show') || overlayModal.classList.contains('show');
      document.body.classList.toggle('no-scroll', overlayIsVisible);
    };

    // Listen for events to toggle overlay
    document.addEventListener('overlay:hide', () => {
      toggleOverlay(false);
    });

    document.addEventListener('overlay:modal:hide', () => toggleOverlay(false));

    document.addEventListener('overlay:navigation:hide', () => {
      toggleOverlay(false, true);
    });

    document.addEventListener('overlay:show', () => {
      toggleOverlay(true);
    });

    document.addEventListener('overlay:modal:show', () => {
      toggleOverlay(true);
    });

    document.addEventListener('overlay:navigation:show', () => {
      toggleOverlay(true, true);
    });

    // Reset everything when clicking on overlay
    overlayModal.addEventListener('click', () => {
      toggleOverlay(false);
      document.dispatchEvent(new CustomEvent('modal:close'));
    });

    overlayNavigation.addEventListener('click', () => {
      toggleOverlay(false, true);
      document.dispatchEvent(new CustomEvent('navigation:reset'));
    });

  };

  if (overlayNavigation && overlayModal) {
    initialize();
  }

});


